import React from 'react'
import ContentContainer from '../../components/ContentContainer'

const HomeView = () => {
    return <ContentContainer title='Welcome'>
        <p>Hello!</p>
        <p>This is the website of Jeffrey C. Lam.</p>
        <p>It's made with React!</p>
        <p>Thanks for visiting :)</p>
    </ContentContainer>
}

export default HomeView