import React from 'react'
import styles from './AboutView.module.css'
import ContentContainer from '../../components/ContentContainer'
import selfPortrait from '../../common/assets/selfie.png'

const AboutView = () => {
    return <ContentContainer title='About Me'>
        <p>I am a first-generation Chinese American citizen, born and raised in Sacramento, now living in New York City.</p>
        <p>I work as an Engineering Manager for the Finance team at Fora Travel, Inc.</p>
        <p>I graduated from Cal Poly SLO with a BS in Computer Science.</p>
        <p>I am growing as a leader, developer, and person.</p>
        <p>I am looking to make a positive impact on my community, my society, and the world.</p>
        <img className={styles.selfPortrait} src={selfPortrait} alt='vectorized self portrait'/>
    </ContentContainer>
}

export default AboutView