import React from 'react'
import ContentContainer from '../../components/ContentContainer'
import WorkTile from '../../components/WorkTile'
import _ from 'lodash'

const workHistory = [
    {
        company: 'Fora Travel, Inc.',
        jobTitle: 'Engineering Manager | Finance Team',
        timeFrame: '11/24 - Present',
        description: [
            'Leading my team of engineers towards success',
            'Prioritizing product development and feasibility according to technical capacity',
            'Adapting product and team processes for project management efficiency and optimization',
            'Overseeing success of a project that is projected to save $1.3 million by the end of 2025',
            'Supporting a project that helps reconcile millions of dollars per month in received payments'
        ]
    },
    {
        company: 'Fora Travel, Inc.',
        jobTitle: 'Backend Engineer (Fullstack)',
        timeFrame: '04/23 - 11/24',
        description: [
            'Building features and tools in Python/Django and TypeScript/Next.js',
            'Automating financial operations that maintain records of hundreds of millions of dollars',
            'Formalized and documented interview processes for incoming engineering candidates',
        ],
    },
    {
        company: 'Tempus Labs, Inc.',
        jobTitle: 'Software Engineer',
        timeFrame: '11/20 - 04/23',
        description: [
            'Worked primarily with JavaScript, TypeScript, Node.js, NestJS, React/Redux, AWS, and GCP',
            "Expanded on backend functionality of Node.js servers utilizing both ExpressJS and NestJS",
            "Built frontend components using React, Redux, and Contexts",
            "Improved automated backend testing speeds by 72%",
            "Designed and implemented persisting document highlights on frontend rendered PDFs",
            "Assisted in automation of backfilling hundreds of thousands of data object entries",
        ],
    },
    {
        company: 'Digital Foundry',
        jobTitle: 'Software Engineer',
        timeFrame: '04/18 - 10/20',
        description: [
            'Performed as a full stack engineer connecting Go, MySQL, and React/Redux.',
            'Developed RESTful Go APIs and React components for generating election ballots.',
            'Implemented programmatic text/glyph rendering to PDF for 13 languages.',
            'Applied TensorFlow/Keras ML to research parrot identity recognition.',
            'Setup and maintained Docker microservices for project features',
            'Mentored a junior developer in full stack development.',
        ],
    },
    {
        company: 'Organizer Inc.',
        jobTitle: 'Software Engineer Intern',
        timeFrame: '07/17 - 09/17',
        description: [
            'Administered regression and QA testing to verify software quality and functionality.',
            'Improved existing Android and Coffeescript code bases to enhance mobile user experience.',
            'Participated in industry level problem solving and sprint planning with agile scrum.',
        ],
    },
]

const ResumeView = () => {
    return (
        <ContentContainer title="Work History">
            {_.map(workHistory, (job) => {
                return (
                    <WorkTile key={job.company} job={job} />
                )
            })}
        </ContentContainer>
    )
}

export default ResumeView